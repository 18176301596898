import BookIcon from "@mui/icons-material/Book";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import "./HomePage.css";

function HomePage() {
  const dancestyle_images = `${process.env.REACT_APP_AWS_S3_BUCKET_URL}/dancestyle_images`;

  return (
    <Box
      sx={{
        backgroundImage: `url("${dancestyle_images}/home-page-wallpaper-aachener-weiher-mirrored.jpg")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        minHeight: "84vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flex: 1,
        overflowY: "auto",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          component="h1"
          sx={{
            color: "white",
            textShadow: "1px 1px 10px black",
            fontSize: { xs: "2rem", md: "3rem" },
            mb: 2,
            padding: "4px 8px",
            fontWeight: "bold",
            fontFamily: "monospace",
          }}
        >
          Explore DanceMaps
        </Typography>
        <Grid container spacing={2}>
          {/* Card 1  */}
          <Grid item sm={12} md={6} lg={3}>
            <Card sx={{ boxShadow: 6, border: "4px solid white" }}>
              <CardMedia
                component="img"
                height="140"
                image={`${dancestyle_images}/home-page-festivals.jpeg`}
                alt="Festivals"
              />
              <CardContent>
                <Button
                  component={Link}
                  to="/festival"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<CalendarMonthIcon />}
                  sx={{ py: 1.5 }}
                >
                  Festivals
                </Button>
                <Typography
                  variant="body2"
                  align="center"
                  mt={1}
                  sx={{
                    fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                    padding: "4px 8px",
                  }}
                >
                  Discover upcoming dance festivals worldwide
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Card 2 */}
          <Grid item sm={12} md={6} lg={3}>
            <Card sx={{ boxShadow: 6, border: "4px solid white" }}>
              <CardMedia
                component="img"
                height="140"
                image={`${dancestyle_images}/home-page-local-parties.jpeg`}
                alt="Local Parties"
              />
              <CardContent>
                <Button
                  component={Link}
                  to="/local_party"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<LocationOnIcon />}
                  sx={{ py: 1.5 }}
                >
                  Local Parties
                </Button>
                <Typography
                  variant="body2"
                  align="center"
                  mt={1}
                  sx={{
                    fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                    padding: "4px 8px",
                  }}
                >
                  Find local socials and events near you
                </Typography>
              </CardContent>
            </Card>
            {/* Card 3 */}
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <Card sx={{ boxShadow: 6, border: "4px solid white" }}>
              <CardMedia
                component="img"
                height="140"
                image={`${dancestyle_images}/home-page-dictionary.jpeg`}
                alt="Dictionary"
              />
              <CardContent>
                <Button
                  component={Link}
                  to="/dance_dictionary"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<BookIcon />}
                  sx={{ py: 1.5 }}
                >
                  Dictionary
                </Button>
                <Typography
                  variant="body2"
                  align="center"
                  mt={1}
                  sx={{
                    fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                    padding: "4px 8px",
                  }}
                >
                  Explore a dance dictionary with figures
                </Typography>
              </CardContent>
            </Card>
            {/* Card 4 */}
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <Card sx={{ boxShadow: 6, border: "4px solid white" }}>
              <CardMedia
                component="img"
                height="140"
                image={`${dancestyle_images}/home-page-songs-charts.jpeg`}
                alt="Songs Charts"
                sx={{ objectFit: "cover", position: "0px 0px" }}
              />
              <CardContent>
                <Button
                  component={Link}
                  to="/songs_charts"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<QueueMusicIcon />}
                  sx={{ py: 1.5 }}
                >
                  Songs Charts
                </Button>
                <Typography
                  variant="body2"
                  align="center"
                  mt={1}
                  sx={{
                    fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                    padding: "4px 8px",
                  }}
                >
                  Browse the latest and popular dance songs
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HomePage;
