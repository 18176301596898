import { Facebook, Instagram } from "@mui/icons-material";
import { Box, Grid, IconButton, Link, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  // Hide elements on certain pages
  const hideElements =
    location.pathname === "/local_party" || location.pathname === "/festival";

  if (hideElements) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: 2,
        minHeight: "8vh",
        backgroundColor: "#333",
      }}
    >
      <Grid container spacing={2} sx={{ color: "white" }}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          {/* Copyright */}
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} DanceMaps. All rights reserved.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          {/* Legal Links */}
          <Link href="/privacy-policy" color="inherit" underline="hover">
            Privacy
          </Link>
          <Link href="/terms-of-service" color="inherit" underline="hover">
            Terms
          </Link>
          <Link href="/disclaimer" color="inherit" underline="hover">
            Disclaimer
          </Link>
          <Link href="/impressum" color="inherit" underline="hover">
            Impressum
          </Link>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          {/* Social Media Links */}
          <IconButton
            color="inherit"
            aria-label="Facebook"
            href="https://www.facebook.com/profile.php?id=61560910366260"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ padding: 0 }}
          >
            <Facebook />
          </IconButton>

          <IconButton
            color="inherit"
            aria-label="Instagram"
            href="https://instagram.com/dancemaps_official/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ padding: 0 }}
          >
            <Instagram />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
